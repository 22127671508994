import { List } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import FilterAccordion from './FilterAccordion'
import DrawerSelectedFilters from './DrawerSelectedFilters'
import parseSearchParams from '../../helpers/SearchParams'
import { useRouter } from 'next/router'
import styles from '../../styles/filterDrawer.module.scss';


const FiltersList = (allFilters, onReset) => {

    const [expanded, setExpanded] = useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    };
    const router = useRouter()
    const searchParams = parseSearchParams(router)

    // const currentFilters = allFilters?.filters.filter((filter) => filter.attribute_code !== 'category_uid')
    const currentFilters = allFilters?.filters
    return (
        <>
            {searchParams.filters ?
                <DrawerSelectedFilters allFilters={allFilters?.filters} />
                :
                null
            }
            <List className={styles["filter-drawer-body"]} sx={{ paddingBottom: "5rem !important" }}>
                {currentFilters?.filter((option) =>
                    (option.count > 0 && option.label !== "0" && option.options.filter(obj => obj.label !== "0" && obj.label !== "1").length > 0)).map(item =>
                        <FilterAccordion id={item.attribute_code} key={item.attribute_code} label={item.label}
                            count={item.count} filters={item.options} handleChange={handleChange} expanded={expanded} />
                    )}
            </List >
        </>
    )
}

export default FiltersList