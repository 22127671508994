import { Button, Drawer, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import useTranslation from '../../hooks/useTranslation'
import Close from '@mui/icons-material/Close';
import { useState } from 'react';
import useCss from '../../hooks/useCss';
import FiltersList from './FiltersList';
import styles from '../../styles/filterDrawer.module.scss';
import Badge from '@mui/material/Badge';
import { useRouter } from 'next/router';
import parseSearchParams from '../../helpers/SearchParams';

const FilterDrawer = ({ filters, filtersToHide = [] }) => {

    const router = useRouter()
    const searchParams = parseSearchParams(router)
    const { c } = useCss()
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)

    const strTrimEnd = (str, tr, count = 1) => {
        var p = str.lastIndexOf(tr)
        if (count > 1)
            return strTrimEnd(str, tr, count - 1).slice(0, p).trim()
        else
            return str.slice(0, p)
    }

    const handleResetFilters = () => {
        let s = searchParams
        if (!s.filters) s.filters = {}
        delete s.filters
        delete s.sort
        delete s.page
        router.push(`${strTrimEnd(router.asPath, '.html')}.html${Object.keys(s.filters || {}).length > 0 ? '/' + JSON.stringify(s) : ''}`)
    }

    const [visible, setVisible] = useState("none")
    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 190 && document.querySelector('#mobile_filters_button')) {
            setVisible("flex")
        }
        else if (scrolled <= 190) {
            setVisible("none")
        }
    }

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', toggleVisible);
        }

    }, [])

    return (
        <Grid  >
            {/***************** mobile  filters  ***************/}
            {/* <Grid id="mobile_filters_button" sx={{
                backgroundColor: "white", zIndex: 1000, width: "100%", justifyContent: "center", top: "85px",
                display: { lg: 'none', xs: visible, sm: visible, md: 'none', xl: "none" }, position: "fixed",
                left: 0, pb: 1.2, boxShadow: "0 3px 10px 2px #22222217"
            }}
            >
                <Button onClick={() => setOpen(true)} sx={{
                    backgroundColor: searchParams.filters ? '#404040' : '#fff !important', '& span': { fontSize: "0.8rem" }, border: searchParams.filters ? '1px solid #404040' : '1px solid #c0c0c0',
                    color: searchParams.filters ? 'white' : 'black', width: '10rem', mt: '1.2vh !important', height: '2.1rem', m: "auto", borderRadius: "16px"
                }} >
                    {searchParams.filters ?
                        <Badge color='success' variant='dot'
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            sx={{
                                "& .MuiBadge-badge": {
                                    right: "85px", top: '2px',
                                    backgroundColor: c('red'), height: "7px", minWidth: "7px"
                                }
                            }}
                        >
                            <Typography variant='span' sx={{ width: "19rem" }}>{t("FILTER")}</Typography>
                        </Badge>
                        :
                        <Typography variant='span'>{t("FILTER")}</Typography>
                    }
                </Button>
            </Grid> */}
            <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
                <Button onClick={() => setOpen(true)} sx={{
                    backgroundColor: searchParams.filters ? '#404040' : '#fff !important',
                    color: searchParams.filters ? 'white' : 'black', width: '6rem', mt: '21px !important', height: '2.065rem', m: "auto", borderRadius: "5px", border: searchParams.filters ? '1px solid #404040' : '1px solid #c0c0c0'
                }} >
                    {searchParams.filters ?
                        <Badge color='success' variant='dot'
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            sx={{
                                "& .MuiBadge-badge": {
                                    backgroundColor: c('red')
                                    , right: "-13px", top: '2px', height: "6px", minWidth: "6px"
                                }
                            }}
                        >
                            <Typography sx={{ fontSize: "0.7rem" }} variant="span">{t("FILTER")}</Typography>
                        </Badge>
                        :
                        <Typography sx={{ fontSize: "0.7rem" }} variant="span">{t("FILTER")}</Typography>
                    }
                </Button>
            </Grid>
            <Drawer open={open} sx={{ width: "100%" }} PaperProps={{ sx: { maxWidth: "100%" } }}>
                <Grid role="presentation" id={styles["filter-drawer"]} sx={{ height: "100%", backgroundColor: c('light-gray'), '& .MuiPaper-root.MuiPaper-elevation': { minWidth: "100%", maxWidth: "100%" }, maxWidth: "100%" }}>
                    <Grid className={styles['drawer-header']} columns={12} sx={{
                        display: "flex"
                        , height: "6.4rem"
                    }}>
                        <Grid item xs={4} >
                            <Button onClick={() => setOpen(false)}
                                sx={{
                                    color: "black", backgroundColor: c('gray'),
                                    borderRadius: "50%", minWidth: '45px !important', height: '45px !important', ml: 2, mt: 2, mr: 2
                                }}>
                                <Close />
                            </Button>
                        </Grid>
                        <Grid item xs={8} textAlign="end">
                            <Typography fontSize="32px" marginRight={5} mb={0} mt={0.8} fontWeight="700">
                                {t("Filter")}
                            </Typography>
                            <Typography className='f-blue' onClick={() => { handleResetFilters() }}
                                sx={{ textDecoration: "underline", marginRight: 2.5, mt: -0.5, fontSize: "15px", fontWeight: "500", textDecorationColor: c("blue") }}>
                                {t("Reset all")}
                            </Typography>
                        </Grid>
                    </Grid>
                    <FiltersList filters={filters?.filter(f => !filtersToHide?.includes(f.attribute_code))} />
                </Grid>
            </Drawer>
        </Grid >
    )
}

export default FilterDrawer