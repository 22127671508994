import { Box, Collapse, Grid, Typography } from "@mui/material"
import { useRouter } from "next/router";
import parseSearchParams from "../../helpers/SearchParams";
import DropdownFilter from "../dropdown-filter"
import PriceFilter from "../price-filter"
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useTranslation from "../../hooks/useTranslation";
import useCss from "../../hooks/useCss";
import { useEffect, useState } from "react";

const FiltersSection = ({ filtersData, styles, filtersToHide = [] }) => {

	const theme = createTheme({
		breakpoints: {
			values: {
				xs: 0,
				sm: 425,
				md: 768,
				lg: 1026,
				xl: 1540,
				xxl: 1600
			}
		}
	})

	const { t } = useTranslation()
	const { c } = useCss()
	const router = useRouter()
	const searchParams = parseSearchParams(router)

	const strTrimEnd = (str, tr, count = 1) => {
		var p = str.lastIndexOf(tr)
		if (count > 1)
			return strTrimEnd(str, tr, count - 1).slice(0, p).trim()
		else
			return str.slice(0, p)
	}

	const handleResetFilters = () => {
		let s = searchParams
		if (!s.filters) s.filters = {}
		delete s.filters
		delete s.sort
		delete s.page
		if (router.asPath.includes('.html')) {
			router.push(`${strTrimEnd(router.asPath, '.html')}.html${Object.keys(s.filters || {}).length > 0 ? '/' + JSON.stringify(s) : ''}`)
		} else {
			router.push(`${strTrimEnd(router.asPath, '/result')}/result${'/' + JSON.stringify(s)}`)
		}
	}

	const [showMoreFilters, setshowMoreFilters] = useState(false)
	const handleChange = () => {
		setshowMoreFilters((prev) => !prev);
	}

	// const currentFilters = filtersData?.products.aggregations.filter((filter) => filter.attribute_code !== 'category_uid')
	const currentFilters = filtersData?.products.aggregations.filter((filter) => !filtersToHide.includes(filter.attribute_code))
	console.log(currentFilters);


	return (
		<Grid position='relative' >
			<Grid item className={styles.filters} sx={{ display: { lg: 'block', xs: 'none', xl: "block", md: "block", sm: "none" }, position: "relative" }}>
				<Collapse in={showMoreFilters} collapsedSize='60px'
					easing={{
						enter: "cubic-bezier(0, 2, 5, 0)",
						exit: "linear"
					}}
				>
					<ThemeProvider theme={theme} >
						<Grid display="flex" justifyContent="center" width="100%">
							<Grid display={'flex-wrap'} container justifyContent="left">
								{currentFilters?.filter((option) =>
									(option.count > 0 && option.label !== "0" && option.options.filter(obj => obj.label !== "0" && obj.label !== "1").length > 0)).sort((a, b) => (+((searchParams?.filters?.[a?.attribute_code] === undefined)) - +((searchParams?.filters?.[b?.attribute_code] === undefined)))).map(item =>
										<Box key={item.attribute_code} sx={{ width: item.attribute_code === "category_uid" ? "300px" : "235px" }} pt="6px" height={"50px"}>
											{item.attribute_code === "price" || item.attribute_code === "discountedPrice" ?
												<PriceFilter id={item.attribute_code} count={item.count}
													label={item.label} options={item.options} searchParams={searchParams}
												/>
												:
												<DropdownFilter id={item.attribute_code} key={item.attribute_code} label={item.label}
													count={item.count} options={item.options} searchParams={searchParams} />
											}
										</Box>
									)}
							</Grid>
						</Grid>
					</ThemeProvider>
				</Collapse>
				<Grid container position="relative" sx={{ justifyContent: "space-between" }} px="35px">
					<Grid className={styles.links + " " + styles.reset}>
						{searchParams?.filters ?
							<a onClick={handleResetFilters} >{t("Reset filter")}</a>
							:
							null
						}
					</Grid>

					<Grid sx={{ display: { md: "block", xs: "none" } }} className={styles.more + " " + styles.links}>
						{currentFilters?.filter((option) =>
							(option.count > 0 && option.label !== "0" && option.options.filter(obj => obj.label !== "0" && obj.label !== "1").length > 0)).length > 7
							?
							showMoreFilters ?
								<Box component="span" sx={{ textDecoration: 'underline !important', cursor: "pointer" }} onClick={() => { handleChange() }}>
									{t('Less filters ')}
								</Box>
								:
								<Box component="span" sx={{ textDecoration: 'underline !important', cursor: "pointer" }} onClick={() => { handleChange() }}>
									{t("More filters")}
								</Box>
							:
							null
						}
					</Grid>
				</Grid>
			</Grid>

			{/***************** Fixed filters section *****************/}
			{/* {searchParams?.filters ?
				<Grid sx={{ display: { md: "block", xs: "none" } }}>
					<Grid id='fixed_filters'
						sx={{
							display: 'none', overflowY: 'hidden', height: '8vh', position: 'fixed', top: { xl: stickyFiltersPos, md: 150 }, right: 0, left: { xl: '400px', md: "350px" },
							zIndex: 3, padding: "9px 0.9rem 4rem 11px", background: c('white'), boxShadow: '0px 8px 5px -4px #2C2C2C0A'
						}}
					>
						<ThemeProvider theme={theme}>
							<Grid display={'flex'} width="100%" columns={6} container justifyContent="center" position="relative">
								{filtersData?.products.aggregations.filter((f) => searchParams.filters[f.attribute_code]).slice(0, 3).map((item) =>
									<Grid key={item.attribute_code} item xs={1} mt={0.8} >
										{item.attribute_code === "price" ?
											<PriceFilter id={item.attribute_code} count={item.count}
												label={item.label} options={item.options} searchParams={searchParams}
											/>
											:
											<DropdownFilter id={item.attribute_code} key={item.attribute_code} label={item.label}
												count={item.count} options={item.options} searchParams={searchParams} />
										}
									</Grid>
								)}
								{(selcFilters - 3) > 0 ?
									<Grid item xs={1} p={1.5}>
										<Typography onClick={() => window.scrollTo({ top: 0 })} variant="span"
											sx={{ cursor: "pointer", background: c('dark-gray'), color: c('white'), ':hover': { background: c('light-black') }, height: "2.3rem", display: "flex", pt: 0.7, pl: 1.2, width: "2.3rem", fontSize: "16px", fontWeight: "600", borderRadius: "50%" }}>+{selcFilters - 3}
										</Typography>
									</Grid>
									: null
								}
							</Grid>
						</ThemeProvider>
					</Grid>
				</Grid>
				:
				null
			} */}


		</Grid>
	)
}

export default FiltersSection