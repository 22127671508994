import { Grid } from '@mui/material'
import React, { useState, useEffect, memo } from 'react'
import ProductCard from '../product-card/productCard'
import { useQuery } from '@apollo/client';
import { GET_PRODUCTS_PRICES } from '../../graphql/queries';

const RenderData = ({ page, products, metaData, category_path, storeData, showLeftSide, id, variables, sx = {}, forSearch }) => {


    console.log({ ...variables, currentPage: page });

    const { data: pricesData } = useQuery(GET_PRODUCTS_PRICES, { variables: { ...variables, currentPage: page }, skip: forSearch, fetchPolicy: "network-only", context: { fetchOptions: { method: "GET" } } })
    const itemsPrices = pricesData?.getProductsPrices?.items

    const [productsData, setProductsData] = useState(products)
    useEffect(() => {
        if (itemsPrices && itemsPrices.length > 0 && !forSearch) {
            const temp = products.map(item => {
                const sel = itemsPrices?.filter(item1 => item1.sku === item.sku)?.at(0) || null
                if (sel) {
                    return ({ ...item, gspunna_regular_price: sel.gspunna_regular_price, price_range: sel.price_range, special_price: sel.special_price, stock_text: sel.stock_text, stock_color: sel.stock_color, stock_color_label: sel.stock_color_label, custom_stock_item: sel.custom_stock_item })
                }
                else {
                    return null
                }
            }).filter(Boolean)
            setProductsData(temp)
        }
    }, [itemsPrices])

    return (
        productsData?.map((item) =>
            <Grid key={(item.canonical_url + item.sku)} id={(item.canonical_url + item.sku)} item xs={1}  >
                <ProductCard
                    product={item}
                    storeData={storeData}
                    category_path={category_path}
                    metaData={metaData}
                    variables={{ ...variables, currentPage: page }}
                    prefetch={false}
                />
            </Grid>
        )
    )
}

export default memo(RenderData)