import { Button, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import Close from '@mui/icons-material/Close';
import useCss from '../../hooks/useCss';
import { useState } from 'react';
import { useRouter } from 'next/router';
import parseSearchParams, { strEncode } from '../../helpers/SearchParams';
import useTranslation from '../../hooks/useTranslation';

const DrawerSelectedFilters = ({ allFilters }) => {
    const { c } = useCss()
    const { t } = useTranslation()
    const [searchParams1, setSearchParams1] = useState({})
    const router = useRouter()

    useEffect(() => {
        setSearchParams1(parseSearchParams(router))
    }, [router])

    const DeleteFiltre = (code, value) => {
        let s = parseSearchParams(router)
        if (Boolean(s.filters[code])) {
            s.filters[code] = s.filters[code].filter(i => i !== value)
        }
        if (s.filters[code]?.length === 0) {
            delete s.filters[code]
            if (Object.keys(s.filters).length === 0)
                delete s.filters
            delete s.page
        }
        var localParam = Object.keys(s).length > 0 ? "/" + strEncode(JSON.stringify(s)) : ""
        router.push(router.asPath.split('/').slice(0, -1).join('/') + localParam)
    }

    const DeletePriceFiltre = () => {
        let s = parseSearchParams(router)
        delete s.filters["price"]
        if (Object.keys(s.filters).length === 0)
            delete s.filters
        delete s.page
        var localParam = Object.keys(s).length > 0 ? "/" + strEncode(JSON.stringify(s)) : ""
        router.push(router.asPath.split('/').slice(0, -1).join('/') + localParam)
    }

    const getFilterLabel = (code, value) => {
        const CCODE = (code==="category_id") ?  code="category_uid"  : code 
        for (const f of allFilters) {
            if (f.attribute_code === CCODE) {
                for (const option of f.options) {
                    if (option.value === value) {
                        return `${f.label}: ${option.label}`
                    }
                }
            }
        }
    }
    const strTrimEnd = (str, tr, count = 1) => {
        var p = str.lastIndexOf(tr)
        if (count > 1)
            return strTrimEnd(str, tr, count - 1).slice(0, p).trim()
        else
            return str.slice(0, p)
    }


    return (

        <Grid sx={{ paddingBottom: "1rem", mb: "1rem", borderBottom: `2px solid ${c('gray')}`, mx: "5%" }}>
            <Grid container columnGap={1} columns={10} sx={{ justifyContent: "left" }}>
                {searchParams1.filters ?
                    Object.keys(searchParams1.filters).map((key) =>
                        key === "price" ?
                            <Grid key={key.value} item height="2rem" mb={1}>
                                <Button
                                    sx={{
                                        color: "white",
                                        maxHeight: '100% !important',
                                        backgroundColor: c('black'), borderRadius: "5px", textAlign: "center", py: "1rem", pb: "1rem"
                                        , justifyContent: "space-between", pr: "0.6rem", pl: "0.9rem", width: "100%"
                                    }}
                                    onClick={() => { DeletePriceFiltre() }}
                                >
                                    {t('Price')}:
                                    {searchParams1.filters[key].map((value, index) =>
                                        <Typography component="span" sx={{ ml: 0.5, fontSize: "14px", mt: "-2px" }} className="white" key={index}>
                                            {value}
                                            {index === 0 ? <> -</> : null}
                                        </Typography>

                                    )}
                                    <Close sx={{ ml: 1 }} fontSize='small' />
                                </Button>
                            </Grid>
                            :
                            searchParams1.filters[key].map(value =>
                                <Grid key={value} item height="2rem" mb={1}>
                                    <Button
                                        sx={{
                                            color: "white",
                                            maxHeight: '100% !important',
                                            backgroundColor: c('black'), borderRadius: "5px", textAlign: "center", py: "1rem", pb: "1rem"
                                            , justifyContent: "space-between", pr: "0.6rem", pl: "0.9rem", width: "100%"
                                        }}
                                        onClick={() => { DeleteFiltre(key, value) }}
                                    >
                                        <Typography component="span" sx={{ fontSize: "13px", mt: "-2px" }} className="white">
                                            {

                                                getFilterLabel(key, value)

                                            }
                                        </Typography>
                                        <Close sx={{ ml: 1 }} fontSize='small' />
                                    </Button>
                                </Grid>
                            )

                    )
                    :
                    null
                }
            </Grid>
        </Grid >

    )
}

export default DrawerSelectedFilters