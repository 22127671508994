import { Grid } from '@mui/material';
import LeftSideCategories from './left-side-categories';
import LeftSideCategorySelected from './left-side-category-selected';
import styles from '../../styles/leftSide.module.scss'

const LeftSide = ({ catChildData, selectedCat, categorySelectedId }) => {
	return (
		<Grid id="left-side-sticky" className={styles.left_side} sx={{ display: { md: "block", xs: "none" }, minWidth: { md: "270px", lg: "300px" }, maxWidth: 300, position: "sticky", top: 190, transition: "top 0.2s linear", mb: 4 }}>
			{
				catChildData.categories.items.filter((cat) => selectedCat.id !== cat.id).length > 0 ?
					selectedCat.children?.length > 0 ?
						catChildData.categories.items.filter((cat) => selectedCat.id !== cat.id).sort((a, b) => a.level - b.level).map((item) =>
							<LeftSideCategories catChildData={item} styles={styles} key={item.id} />
						)
						:
						catChildData.categories.items.sort((a, b) => a.level - b.level).filter((cat) => selectedCat.id !== cat.id && selectedCat.level > cat.level + 1).map((item) =>
							<LeftSideCategories catChildData={item} styles={styles} key={item.id} />
						)
					:
					null
			}
			<LeftSideCategorySelected styles={styles}
				selectedCat={selectedCat?.children?.length > 0
					?
					selectedCat
					:
					catChildData.categories.items.filter((cat) => (selectedCat.level - cat.level) === 1)[0]
				}
				categorySelectedId={categorySelectedId} catData={selectedCat}
			/>
		</Grid>
	);
}

export default LeftSide;