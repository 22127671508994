import Pagination from "../pagination/pagination";
import ProductList from "../product-list/productList";
import { memo } from "react";

const PaginatedProductList = ({ data, renderConfigProps, forSearch }) => {

    return (
        <>
            <ProductList data={data} renderConfigProps={renderConfigProps} forSearch={forSearch}/>
            <Pagination pageCount={data?.products.page_info.total_pages} btnCount={3} currPage={data?.products.page_info.current_page}/>
        </>
    );
}

export default memo(PaginatedProductList);