import { useEffect } from "react"
import useTranslation from "../../hooks/useTranslation"
import styles from '../../styles/plp.module.scss'
import { Container, Grid, Typography } from "@mui/material"
import DropdownSort from "../../components/dropdown-sort"
import LeftSide from "../../components/left-side"
import ImageCategoryBaner from "./imageCategoryBaner"
import FilterDrawer from "../filter-drawer/FilterDrawer";
import FiltersSection from "./FiltersSection"
import ProductCollection from "../product-collection"
import { cacheKey } from "../LazyProducts"
import parseSearchParams from "../../helpers/SearchParams"
import { useRouter } from "next/router"


const PLP = ({ plpData, message = 1, forSearch = false, filtersToHide, searchParams = null }) => {
    const { catData, data, filtersData, sortData, selectedCat, catChildData, metaData, storeConfig, categorySelectedId, variables } = plpData
    console.log(variables);
    
    const { t } = useTranslation()
    const catItem = catData?.categories?.items?.at(0) || {}
    const showLeftSide = !forSearch && (selectedCat?.children?.length > 0 || catChildData?.categories?.items.filter((cat) => selectedCat?.id !== cat.id).length > 0)

    const router = useRouter()
    const searchParamsClient = parseSearchParams(router)

    useEffect(() => {
        if (!window['categoryCache']) {
            window['categoryCache'] = {};
        }
        if (!window['categoryCache'][JSON.stringify(variables.filters)]) {
            window['categoryCache'][JSON.stringify(variables.filters)] = {}
        }
        window['categoryCache'][JSON.stringify(variables.filters)][cacheKey(variables)] = data.products.items
        Object.keys(window['categoryCache']).map(key => {
            if (key !== JSON.stringify(variables.filters)) {
                delete window['categoryCache'][key]
            }
        })
        for (let index = variables.currentPage + 1; index <= variables.pageSize; index++) {
            const vars = { ...variables, currentPage: index }
            delete window['categoryCache'][JSON.stringify(variables.filters)][cacheKey(vars)]
        }
    }, [])

    return (
        <Container maxWidth="xl" id={styles.plp} sx={{ px: { xl: 3, xs: 0, lg: 2, md: 2 } }}>
            <Grid display={"flex"} sx={{ pb: 2, pt: 4.4 }}>
                {showLeftSide
                    ?
                    <Grid display={{ xs: 'none', lg: 'flex' }} flexDirection="column" position="relative" >
                        <LeftSide catChildData={catChildData} selectedCat={selectedCat} categorySelectedId={categorySelectedId} />
                    </Grid>
                    :
                    null
                }
                {message === 1 ?
                    <Grid id="plp_right_side" className="plp_right_side" sx={{ width: "100%", pl: { md: showLeftSide ? 3 : 0, xs: 0 } }} >
                        <Grid item xs={1} mb={{ xs: 2, md: 0 }}>
                            {!searchParams?.q ?
                                <ImageCategoryBaner imgSrc={catItem?.image} data={catItem} styles={styles} />
                                :
                                null
                            }
                            <Grid sx={{ display: { md: "none", xs: "flex" }, px: 3, height: { lg: "6vh", xs: "6vh", md: "8vh" }, justifyContent: "space-between" }}>
                                <FilterDrawer filters={filtersData.products.aggregations} filtersToHide={filtersToHide} />
                                <Grid sx={{ mt: 2 }}>
                                    <DropdownSort forSearch={forSearch} names={sortData?.products.sort_fields} searchParams={searchParamsClient} variables={variables} />
                                </Grid>
                            </Grid>
                            <FiltersSection filtersData={filtersData} styles={styles} filtersToHide={filtersToHide} />
                        </Grid>
                        <Grid container paddingTop={"10px"} px="35px">
                            <Grid column={2} container alignItems="center" mb={3} >
                                <Grid item lg={8} md={7} xs={12} sx={{ textAlign: { xl: 'unset', xs: 'left' }, pl: 0, pr: { md: 3, xs: 4 } }}>
                                    <Typography fontSize="1.2rem" fontWeight="800" mt={1} >
                                        {searchParams?.q ? (t('Search results for').toUpperCase()) + ` "${searchParams?.q}"` + " " : null}
                                        {data?.products?.total_count}
                                        {" " + t("products")}
                                    </Typography>
                                </Grid>
                                <Grid item lg={4} sm={6} md={5} xs={8} sx={{ display: { lg: 'block', xs: 'none', xl: "block", md: "block", sm: "none" }, mt: 2 }} >
                                    <DropdownSort forSearch={forSearch} names={sortData?.products.sort_fields} searchParams={searchParamsClient} variables={variables} />
                                </Grid>
                            </Grid>
                            <ProductCollection data={data} catData={catData} storeConfig={storeConfig} forSearch={forSearch} metaData={metaData} showLeftSide={showLeftSide} variables={variables} prefetch={false} />
                        </Grid>
                    </Grid>
                    :
                    <Grid display="flex" justifyContent="center" width="100%" alignItems="start" pt={5}>
                        <Typography variant="h5" className="fw-500"> {t("For your search")} <Typography variant="span" className="fw-700">{`"${searchParams?.q}"`}</Typography> {t("no results were found")}.</Typography>
                    </Grid>
                }
            </Grid>
        </Container >
    );

}

export default PLP;