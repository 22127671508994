import { Grid } from "@mui/material"
import styles from '../../styles/pagination.module.scss'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useRouter } from "next/router";
import { Link } from "../my-link/link";
import parseSearchParams, { strEncode } from "../../helpers/SearchParams";

const Pagination = ({ pageCount, btnCount = 5, currPage }) => {

    const router = useRouter()
    const searchParams = parseSearchParams(router)

    if (pageCount <= 1) {
        return (<Grid></Grid>)
    }


    const getLink = (page) => {
        if (page >= 1 && page <= pageCount) {
            searchParams.page = page
            const url = `${router.asPath.endsWith('.html') ? router.asPath : router.asPath.split('/').slice(0, -1).join('/')}/${strEncode(JSON.stringify(searchParams))}`
            return url
        }
    }

    const generateButtons = () => {
        let btns = new Set()
        let start = currPage - Math.ceil((btnCount - 1) / 2)
        if (start < 1) start = 1
        let end = start + btnCount - 1;
        if (end > pageCount) {
            end = pageCount
            if ((end - btnCount) + 1 > 1) {
                start = (end - btnCount) + 1
            }
            else {
                start = 1
            }
        }

        for (let index = start; index <= end; index++) {
            let item = <li>
                <Link href={getLink(index)} className={currPage === index ? styles.page_active : ""}>{index}</Link>
            </li>
            btns.add(item)
        }
        return btns
    }

    return (
        <Grid className={styles.pagination + " w-100 mt-2"} displayPrint= "none">
            <Grid className="w-100" sx={{display:"flex", justifyContent:{xs:"center",sm:"end"} }}>
                <ul className="list-unstyled" style={{ display: "flex", alignItems: "center" }} >
                    {
                        currPage > 1 ?

                            <Link href={getLink(currPage - 1, router)}>
                                <ChevronLeftIcon type="button" id="go-back" style={{ opacity: (currPage === 1 ? 0.4 : 1) }} />
                            </Link>
                            :
                            <div>
                                <ChevronLeftIcon type="button" id="go-back" style={{ opacity: (currPage === 1 ? 0.4 : 1), cursor: "auto" }} />
                            </div>
                    }
                    {
                        generateButtons()
                    }
                    {
                        currPage < pageCount ?

                            <Link href={getLink(currPage + 1, router)}>
                                <ChevronRightIcon style={{ opacity: (currPage === pageCount ? 0.4 : 1) }} />
                            </Link>
                            :
                            <div>
                                <ChevronRightIcon style={{ opacity: (currPage === pageCount ? 0.4 : 1), cursor: "auto" }} />
                            </div>
                    }
                </ul>
            </Grid>
        </Grid>
    );
}

export default Pagination;