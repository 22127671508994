import { useRouter } from 'next/router'
import React, { memo, useCallback, useEffect, useState } from 'react'
import { cacheKey } from '.'
import NextButton from './next-button'
import parseSearchParams, { strEncode } from '../../helpers/SearchParams'
import RenderData from '../product-list/renderData'


const NextProducts = ({ pagesCount, variables, renderConfigProps, forSearch }) => {
    const router = useRouter()
    const cKey0 = "categoryCache"
    const cKey1 = cacheKey(variables.filters)
    const cKey2 = cacheKey(variables)
    const [display, setDisplay] = useState("none")

    const getLink = useCallback((page) => {
        const searchParams = parseSearchParams(router)
        if (page >= 1 && page <= pagesCount) {
            searchParams.page = page
            if (router.asPath.includes('.html')) {
                return router.asPath.split('.html')[0] + ".html/" + strEncode(JSON.stringify(searchParams))
            } else if (router.asPath.includes('catalogsearch/result')) {
                return router.asPath.split('catalogsearch/result')[0] + "catalogsearch/result/" + strEncode(JSON.stringify(searchParams))
            }
        }
    }, [router])

    useEffect(() => {
        return () => setDisplay("none");
    }, [])

    const items1 = typeof window !== "undefined" ? window?.[cKey0]?.[cKey1]?.[cKey2] : null
    const [items, setItems] = useState(items1)

    useEffect(() => {
        if (typeof window !== "undefined" && !items) {
            window[cKey0] = (window[cKey0] || {})
            window[cKey0][cKey1] = (window[cKey0][cKey1] || {})
            window[cKey0][cKey1][cKey2] = []
            fetch(getLink(variables.currentPage))
                .then((response) => {
                    return response.text();
                })
                .then((htmlContent) => {
                    window[cKey0][cKey1][cKey2] = JSON.parse('{"items":' + htmlContent.split('{"data":{"products"')[1].split('"items":')[1].split(',"page_info"')[0] + "}")?.items;
                    setItems(JSON.parse('{"items":' + htmlContent.split('{"data":{"products"')[1].split('"items":')[1].split(',"page_info"')[0] + "}")?.items)
                })
                .catch((error) => {
                    console.error('Error fetching page content:', error);
                });
        }
    }, [cKey0, cKey1, cKey2, items])


    console.log(items);


    return (
        typeof window !== "undefined"
            ?
            <>
                {
                    items && items.length > 0 && display !== "none"
                        ?
                        <RenderData page={variables.currentPage} products={items} key={(cKey0 + cKey1 + cKey2)} {...renderConfigProps} forSearch={forSearch} />
                        :
                        null
                }
                {
                    display === "none" || (display !== "none" && !(items && items.length > 0))
                        ?
                        <NextButton onClick={() => {
                            setDisplay("flex")
                            router.push(getLink(variables.currentPage), undefined, { shallow: true })
                        }
                        } />
                        :
                        null
                }
                {
                    variables.currentPage < pagesCount && display !== "none" && items && items.length > 0
                        ?
                        <NextProducts pagesCount={pagesCount} variables={{ ...variables, currentPage: variables.currentPage + 1 }} renderConfigProps={renderConfigProps} forSearch={forSearch} />
                        :
                        null
                }
            </>
            :
            null
    )
}

export default memo(NextProducts)