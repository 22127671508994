import { Backdrop, Divider, Grid, Popper, Typography } from "@mui/material";
import useCss from "../../hooks/useCss";
import { Link } from '../my-link/link';
import { useEffect, useRef, useState } from "react"

const LeftSideCategories = ({ data, styles, catChildData }) => {
    const { c } = useCss()
    const [open, setOpen] = useState()

    const anchorEl = useRef()
    const El = useRef()
    let hideAfter


    const closeMenu = (e) => {
        const condition = [document.getElementById('pop1'), document.getElementById('menu-poppeerr'), document.getElementById('pop3')].indexOf(e?.relatedTarget) > -1
        if (!condition) {
            setOpen(false)
        }
    }

    const handleOpenPopper = (e) => {
        setOpen(true);
    }


    useEffect(() => {
        if (open) {
            document.querySelector('.plp_right_side') ? document.querySelector('.plp_right_side').style.zIndex = "-2" : null
            document.querySelector('#pp-right-side') ? document.querySelector('#pp-right-side').style.zIndex = "-2" : null
            document.querySelector('#all_header').style.zIndex = "-1"
        }
        else {
            document.querySelector('.plp_right_side') ? document.querySelector('.plp_right_side').style.zIndex = "1" : null
            document.querySelector('#pp-right-side') ? document.querySelector('#pp-right-side').style.zIndex = "1" : null
            document.querySelector('#all_header').style.zIndex = "102"
        }
    }, [open])

    return (
        <Grid key={catChildData.id} sx={{ mb: 2 }}>
            <Typography variant="span"
                onMouseEnter={(e) => handleOpenPopper(e)}
                onMouseLeave={(e) => hideAfter = setTimeout(() => closeMenu(e), 300)}
                ref={anchorEl}
                style={{ cursor: "pointer", display: "flex", alignItems: "center", height: "45px", width: "300px", position: "relative", paddingLeft: "1rem" }}
                className={styles.cat_name + " bg-light-gray " + (open ? styles.opened : "")}
                id="menu-poppeerr"
                sx={{ fontSize: "0.9rem", fontWeight: 600, zIndex: open ? "1500" : "1200", letterSpacing: 0.4 }}
            >
                {catChildData.name?.toUpperCase()}
            </Typography>
            <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 200, backgroundColor: "rgba(0, 0, 0, 0.1) !important" }}
                open={open}
            >
                <Popper
                    id="menu-popperr"
                    open={open}
                    onMouseEnter={(e) => clearTimeout(hideAfter)}
                    onMouseLeave={(e) => hideAfter = setTimeout(() => closeMenu(e), 300)}
                    anchorEl={anchorEl.current}
                    onClose={{}}
                    placement="right-start"
                    sx={{ background: c('light-gray'), borderTopRightRadius: "3px", borderEndStartRadius: "3px", borderEndEndRadius: "3px" }}
                >
                    <Grid p="4px 20px" id="pop3" ref={El} >
                        {catChildData.children.map((cat, index) =>
                            <>
                                <Link key={cat.id} to={cat?.url_path + ".html"}>
                                    <Typography variant="span" sx={{
                                        '&:hover': { color: c('dark-gray') },
                                        lineHeight: "40px", fontSize: "14px", fontWeight: "500", color: c('black')
                                    }}
                                    >
                                        {cat.name}
                                    </Typography>
                                </Link>
                                {index < catChildData.children.length - 1 ? <Divider /> : null}
                            </>
                        )}
                    </Grid>
                </Popper>
            </Backdrop>
        </Grid >
    )
}

export default LeftSideCategories;