import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, FormControlLabel, Grid, List, ListItem, Typography } from '@mui/material'
import React from 'react'
import { useState, useEffect, useCallback } from 'react'
import useCss from '../../hooks/useCss'
import DrawerPriceFilter from './DrawerPriceFilter'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useTranslation from '../../hooks/useTranslation'
import { useRouter } from 'next/router'
import parseSearchParams, { strEncode } from '../../helpers/SearchParams'
import styles from '../../styles/filterDrawer.module.scss';

const FilterAccordion = ({ id = "", label = "", filters, onReset, count, key, expanded, handleChange }) => {

    const router = useRouter()
    const singleSelect = id === "category_uid"
    const [fID, setFID] = useState(id === "category_uid" ? "category_id" : id)
    const { t } = useTranslation()
    const { c } = useCss()
    const [init, setInit] = useState(true)
    const [myFilters, setMyFilters] = useState([])

    useEffect(() => {
        if (fID && router) {
            let f = parseSearchParams(router)
            if (f.filters && f.filters[fID]) {
                setMyFilters(f.filters[fID])
            } else {
                setMyFilters([])
            }
        }
    }, [fID, router])


    const strTrimEnd = (str, tr, count = 1) => {
        var p = str.lastIndexOf(tr)
        if (count > 1)
            return strTrimEnd(str, tr, count - 1).slice(0, p).trim()
        else
            return str.slice(0, p)
    }

    useEffect(() => {
        if (!init && singleSelect) {
            doFilters(myFilters)
        }
    }, [myFilters])


    const handleFilter = (e, value) => {
        setInit(false)
        if (e.target.checked) {
            if (singleSelect)
                setMyFilters([value])
            else
                setMyFilters(prev => [...prev, value])
        }
        else {
            setMyFilters(prev => prev.filter(f => f !== value))
        }
    }


    const doFilters = (myFilters) => {
        let s = parseSearchParams(router)
        if (!s.filters) s.filters = {}
        s.filters[fID] = myFilters
        if (s.filters[fID].length === 0) {
            delete s.filters[fID]
            if (Object.keys(s.filters).length === 0)
                delete s.filters
        }
        delete s.sort
        delete s.page
        var localParam = Object.keys(s).length > 0 ? "/" + strEncode(JSON.stringify(s)) : ""
        if (Object.keys(parseSearchParams(router)).length === 0)
            router.push(router.asPath + localParam)
        else
            router.push(router.asPath.split('/').slice(0, -1).join('/') + localParam)
    }

    // const doFilter = (e, attribute_code, option) => {
    //     var s = { ...searchParams1 }
    //     delete s.page
    //     if (!s.filters) {
    //         s.filters = {}
    //     }
    //     if (e.target.checked) {
    //         if (!s.filters[id]) {
    //             s.filters[id] = []
    //         }

    //         s.filters[id] = distictArr([...s.filters[id], option.value]).sort()

    //         s.filters = Object.fromEntries(Object.entries(s.filters).sort())
    //         s = Object.fromEntries(Object.entries(s).sort())
    //     }
    //     else {
    //         s.filters[id] = s.filters[id].filter(value => value !== option.value)
    //         if (s.filters[id].length === 0) {
    //             delete s.filters[id]
    //         }
    //         if (Object.keys(s.filters).length === 0) {
    //             delete s.filters
    //         }
    //     }

    //     if (Object.keys(s).length === 0) {
    //         router.push(`${router.asPath.endsWith('.html') ? router.asPath : router.asPath.split('/').slice(0, -1).join('/')}`)
    //     }
    //     else {
    //         router.push(`${router.asPath.endsWith('.html') ? router.asPath : router.asPath.split('/').slice(0, -1).join('/')}/${strEncode(JSON.stringify(s))}`)
    //     }

    // }

    const isChecked = useCallback((optionValue) => {
        return myFilters?.includes(optionValue)
    }, [myFilters])

    /* const resetCurrentFilter = () => {
        onReset && onReset()
        setFilters([])
        setFilters([])
    } */

    return (
        <>
            <Accordion expanded={expanded === fID} onChange={handleChange(fID)} sx={{ maxHeight: "unset !important", m: "0 !important", '& .Mui-expanded.MuiAccordionSummary-gutters': { borderTop: `1px solid ${c('gray')} ` } }} className={styles["mobile-accordion"]}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    sx={{ transitionDuration: "7ms", backgroundColor: c('light-gray'), px: 2.5 }}
                    className={styles["mobile-accordion-header"]}
                >
                    <Typography component="p" textTransform="capitalize" >{fID === "price" ? t('Price') : label}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{
                    overflowY: "auto",
                    maxHeight: "375px",
                    pb: 0,
                    px: 0.5
                }} id={fID}>
                    <List className={styles['mobile-accordion-body']} sx={{
                        width: "100%", ml: "auto", mr: "auto", backgroundColor: c('white'), px:2
                    }}>
                        {fID === "price" ?
                            <DrawerPriceFilter id={fID} count={count}
                                label={t('price')} options={filters}
                            />
                            :
                            filters.map((option, index, arr) =>
                                option.count > 0 && option.label !== "0" ?
                                    <Typography component={'span'} variant={'p'} key={option.label}>
                                        <ListItem sx={{
                                            px: 0, ".MuiFormControlLabel-root": { width: "100%", mx: 0 },
                                            ".MuiFormControlLabel-label": { width: "100%", display: "flex", justifyContent: "space-between" }
                                        }}>
                                            <FormControlLabel

                                                control={
                                                    <Checkbox size='small' sx={{ p: 0.5, mx: 0.5, color: c("black") + " !important", fontSize: "10px !important" }}
                                                        checked={isChecked(option.value)}
                                                        value={option.value}
                                                        onChange={(e) => { handleFilter(e, option.value) }}
                                                        id={`chk_${fID}_${option.value}`}
                                                    />
                                                }
                                                label={<>
                                                    <span style={{ fontSize: '14px', fontWeight: "500" }}>{option.label}</span>
                                                    <span className="fs-14 fw-500 mx-1">{option.count}</span>
                                                </>
                                                }
                                            />
                                        </ListItem>
                                    </Typography>
                                    :
                                    null
                            )}
                    </List >
                </AccordionDetails>
                {
                    fID !== "price" && !singleSelect
                        ?
                        <Box sx={{ textAlign: "center", pb: 2, pt: 1 }}>
                            <Button onClick={() => { doFilters(myFilters) }}
                                sx={{
                                    backgroundColor: c('blue'), borderRadius: '5px', color: c('white'),
                                    fontWeight: "600", ':hover': { backgroundColor: c('black') }, px: 5
                                }}
                            >{t("Apply")}
                            </Button>
                        </Box>
                        :
                        null
                }

            </Accordion>
        </>
    )
}

export default FilterAccordion