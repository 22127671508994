import { Button, FormControl, Grid, InputAdornment, InputLabel, OutlinedInput, Select, Slider, TextField, Tooltip } from '@mui/material';
import React from 'react'
import { useState, useEffect } from 'react'
import useCss from '../../hooks/useCss';
import useTranslation from '../../hooks/useTranslation';
import styles from "../../styles/priceFilter.module.scss"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CloseIcon from '@mui/icons-material/Close';
import { useRouter } from 'next/router';
import parseSearchParams, { strEncode } from '../../helpers/SearchParams';


const PriceFilter = ({ count, label = "Price", options, id = "price", onReset }) => {
    const [searchParams, setSearchParams] = useState({})
    const [open, setOpen] = useState(false)
    const { t } = useTranslation()
    const router = useRouter()
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 305,
                width: "330 !important",
                paddingBottom: 20,
                paddingLeft: 30,
                paddingRight: 30,
                paddingTop: 30,
                marginLeft: '4%'
            },
        },
    };

    const { c } = useCss()
    const [values, setValues] = useState([0, count]);
    const [shownValue, setShownValue] = useState([])
    const [prices, setPrices] = useState([parseInt(options[0].value.split('_')[0]), parseInt(options[count - 1].value.split('_')[1])])

    const handleChange1 = (event, newValue) => {
        setValues(newValue);
        setPrices([parseInt(marks[newValue[0]].value), parseInt(marks[newValue[1]].value)])
    };

    const handlePriceInputChange = ({ price1, price2 }) => {
        var result = [...values]
        setPrices([price1 || prices[0], price2 || prices[1]])
        if (price1) {
            var currV = parseInt(price1) + 1
            var i = -1
            marks.forEach((element, q) => {
                if (i === -1 && currV <= parseInt(element.value)) {
                    i = q
                    result[0] = Math.max(0, q - 1)
                }
            })
        }
        if (price2) {
            var currV = parseInt(price2) + 1
            var i = -1
            marks.reverse().forEach((element, q) => {
                if (i === -1 && currV >= parseInt(element.value)) {
                    i = q
                    result[1] = count - q
                }
            })
        }
        setValues(result)
    }

    const strTrimEnd = (str, tr, count = 1) => {
        var p = str.lastIndexOf(tr)
        if (count > 1)
            return strTrimEnd(str, tr, count - 1).slice(0, p).trim()
        else
            return str.slice(0, p)
    }

    const doFilters = (f) => {
        let s = searchParams
        if (!s.filters) {
            s.filters = {}
        }
        s.filters[id] = [...f.map(e => `${e}`)]
        if (s.filters[id].length === 0) {
            delete s.filters[id]
            if (Object.keys(s.filters).length === 0)
                delete s.filters
        }
        setShownValue([...f.map(e => e)])
        delete s.sort
        delete s.page

        var localParam = Object.keys(s).length > 0 ? "/" + strEncode(JSON.stringify(s)) : ""

        if (Object.keys(parseSearchParams(router)).length === 0)
            router.push(router.asPath + localParam)
        else
            router.push(router.asPath.split('/').slice(0, -1).join('/') + localParam)

    }

    const maxHeight = Math.max(...options.map(o => o.count))
    const minRange = Math.min(...options.map(o => o.value.split("_")[0]))
    const maxRange = Math.max(...options.map(o => o.value.split("_")[1]))
    const gap = 4
    const gapsWidth = (count - 1) * (gap)
    const columns_width = Math.max(gapsWidth + (count * 5), 340)
    const colmun = (columns_width - gapsWidth) / count
    const marks = [{ value: parseInt(minRange) }, ...options.map(e => { return { value: parseInt(e.value.split("_")[1]) } })];

    const valuetext = (value) => {
        return `${marks[value].value} CHF`;
    }


    useEffect(() => {
        setSearchParams(parseSearchParams(router))
    }, [router])

    useEffect(() => {
        if (Object.keys(searchParams).length > 0) {
            let def = [parseInt(options[0].value.split('_')[0]), parseInt(options[count - 1].value.split('_')[1])]
            if (searchParams.filters) {
                if (searchParams.filters[id]) {
                    var v = searchParams.filters[id] ? searchParams?.filters[id].map(e => parseInt(e)) : []
                    handlePriceInputChange({ price1: v[0], price2: v[1] })
                    setShownValue(v)
                } else {
                    setPrices(def)
                    setShownValue([])
                    if (JSON.stringify(def) !== JSON.stringify(prices))
                        doFilters([])
                }
            } else {
                setPrices(def)
                setShownValue([])
                if (JSON.stringify(def) !== JSON.stringify(prices))
                    doFilters([])
            }
        }
    }, [searchParams])

    const resetFilter = () => {
        onReset && onReset()
        doFilters([])
        setPrices([parseInt(options[0].value.split('_')[0]), parseInt(options[count - 1].value.split('_')[1])])
        setValues([0, count])
        setShownValue([])
    }

    return (
        <FormControl className={styles.PriceFilter} sx={{ m: 1, width: columns_width, mb: 2 }} >
            <Grid display={shownValue.length === 0 ? 'none' : 'block'} className={styles.reset_fliter}>
                <Button onClick={() => resetFilter()}>
                    <CloseIcon className='white' style={{ width: "1rem" }} />
                </Button>
            </Grid>
            <InputLabel variant="standard"
                sx={{
                    fontSize: "15px", overflow: "hidden", textOverflow: "ellipsis", color: c('black'),
                    fontWeight: "500", mr: "3px", maxWidth: "79%", top: "auto", left: "15px", bottom: "28px",
                }}
                id="multiple-checkbox-label">
                {t('Price')}
            </InputLabel>
            <Select
                id={styles.PriceSelect}
                label='price'
                inputProps={{ 'aria-label': 'Without label' }}
                className={shownValue.length === 0 ? styles.filterSelect : styles.filterSelect + ' bg-light-black bg-hover-black f-white'}
                labelId="price_select"
                MenuProps={{ ...MenuProps, PaperProps: { ...MenuProps.PaperProps, width: columns_width } }}
                input={<OutlinedInput label={label} />}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                open={open}
                IconComponent={() => open ? <ExpandLessIcon style={{ width: "1.4rem" }} /> : <ExpandMoreIcon style={{ width: "1.4rem" }} />}
                value={shownValue}
                renderValue={(selected) => selected.join(" - ")}
                sx={{
                    '& .MuiInputBase-input.MuiOutlinedInput-input': { fontSize: '0.86rem' }, ':hover': {
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: c('dark-gray'),
                        }
                    }
                }}
            >
                <Grid className="columns" width={columns_width} height={(100 * options.at(0).count / maxHeight) ? 100 : 0} position="relative" >
                    {options.map((item, i) => {
                        var [x1, x2] = item.value.split("_")
                        return <Tooltip title={item.count} placement="top" key={i}>
                            <Grid className={((parseInt(x1) >= prices[0] && parseInt(x2) <= prices[1]) ? " bg-yellow" : " bg-gray")}
                                sx={{ position: 'absolute', bottom: -3, left: (colmun + gap) * i, width: colmun }} height={100 * item.count / maxHeight}>
                            </Grid>
                        </Tooltip>
                    }
                    )}
                </Grid>
                <Grid className={styles.slider} width={columns_width} >
                    <Slider
                        aria-label="Restricted values"
                        value={values}
                        onChange={handleChange1}
                        valueLabelDisplay='auto'
                        valueLabelFormat={valuetext}
                        step={1}
                        marks={marks}
                        max={count}
                        min={0}
                    />
                </Grid>
                <Grid className={styles.fields} justifyContent="space-between">
                    <TextField
                        id="From"
                        label={t("From")}
                        className={styles.field}
                        type="number"
                        value={prices[0]}
                        onChange={e => handlePriceInputChange({ price1: e.target.value })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            min: minRange,
                            max: prices[1] - 50,
                            step: 50,
                            draggable: true

                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">CHF</InputAdornment>,
                        }}
                        sx={{ '& input[type=number]::-webkit-inner-spin-button': { '-webkit-appearance': '' } }}

                    />
                    <TextField
                        id="To"
                        label={t("To")}
                        className={styles.field}
                        type="number"
                        value={prices[1]}
                        onChange={e => handlePriceInputChange({ price2: e.target.value })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            min: prices[0] + 50,
                            max: maxRange,
                            step: 50
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">CHF</InputAdornment>,
                        }}
                    />
                </Grid>
                <Grid marginTop={2.5} display="flex" justifyContent="center">
                    <Button
                        variant="contained"
                        sx={{
                            boxShadow: 'unset', width: "100%", borderRadius: "5px", background: c('blue'), height: '33px',
                            ':hover': { backgroundColor: c('light-black'), boxShadow: 'unset' }
                        }}
                        onClick={() => {
                            doFilters(prices)
                        }}
                    >{t("Apply")}</Button>
                </Grid>
            </Select>
        </FormControl >
    )
}

export default PriceFilter