import { Box, Button } from '@mui/material';
import React, { useState } from 'react'
import useTranslation from '../../hooks/useTranslation';
import cssVars from "../../styles/vars.module.scss"

const NextButton = ({ onClick }) => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    return (
        <Box sx={{ width: "100%" }}>
            <Button className="border-1-dark-gray px-4 py-2 mt-4 dark-gray border-hover-blue f-hover-blue"
                sx={{ fontSize: "0.95rem", mx: "auto", p: 0, bgcolor: `${cssVars.white} !important`, display: "block" }}
                onClick={() => { setLoading(true); onClick() }}>
                {
                    loading
                        ?
                        <div className="spinner-border spinner-border-sm" role="status">
                            <span className="visually-hidden"></span>
                        </div>
                        :
                        t("Load more")
                }
            </Button>
        </Box>

    )
}

export default NextButton