import { Box, Breadcrumbs, ListItem, Stack } from "@mui/material";
import Link from "next/link";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import useCss from "../../hooks/useCss";

const BreadCrumb = ({ breadcrumbs, variant = "pp" }) => {

    let brs = [...(breadcrumbs || [])]
    brs = brs.sort((a, b) => a.category_level - b.category_level)
    const { c } = useCss()
    const color = c(variant === "pp" ? "dark-gray" : "white")
    return (
        breadcrumbs
            ?
            <Box component="nav" sx={{ display: { xs: "none", md: "block" } }}>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" sx={{ fill: color }} />}
                    aria-label="breadcrumb"
                >
                    {
                        brs.map((breadcrumb) =>
                            <Box color={color} fontSize="12px" key={breadcrumb.category_name} sx={{ minWidth: "fit-content", ":hover": { color: c("blue") } }}>
                                <Link href={breadcrumb.category_url_path + '.html'}>{breadcrumb.category_name}</Link>
                            </Box>
                        )
                    }
                </Breadcrumbs>
            </Box>
            :
            null
    );
}

export default BreadCrumb;