import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Grid, ListItemText, OutlinedInput, Typography } from '@mui/material';
import styles from '../../styles/dropdownSort.module.scss'
import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useRouter } from 'next/router';
import SortIcon from '@mui/icons-material/Sort';
import { Button } from '@mui/material';
import { strEncode } from '../../helpers/SearchParams';

const DropDownSort = ({ names, searchParams, forSearch, variables }) => {

	const router = useRouter()
	const [open, setOpen] = useState(false)

	const strTrimEnd = (str, tr, count = 1) => {
		var p = str.lastIndexOf(tr)
		if (count > 1)
			return strTrimEnd(str, tr, count - 1).slice(0, p).trim()
		else
			return str.slice(0, p)
	}

	const handleSelect = (value, order) => {
		let s = searchParams || {}
		delete s.page
		s.sort = { [value]: order }
		router.push(`/${router.asPath.endsWith('.html') ? router.asPath : router.asPath.split('/').slice(0, -1).join('/')}/${strEncode(JSON.stringify(s))}`)
	}

	const stylebuttonIcon = {
		'width': '22px'
	};

	const [isSorting, setIsSorting] = useState(false)

	return (
		<Grid container gap={0} className={styles.sort} id={styles.SortDropdown} sx={{ pr: { xl: "0,5rem" }, width: { sm: "100%", xs: "180px" }, flexWrap: "nowrap" }}>
			<FormControl className={styles.sort_dropdown} sx={{ fontSize: "12px" }}>
				<Select
					id="sort_products"
					displayEmpty
					value={forSearch ? searchParams.sort ? (Object.keys(searchParams?.sort)?.at(0) + "--" + Object.values(searchParams?.sort)?.at(0)) : "relevance--DESC" : searchParams?.sort ? Object.keys(searchParams?.sort)[0] : names?.default}
					onChange={(e) => { forSearch ? handleSelect(e.target.value.split("--").at(0), e.target.value.split("--").at(1)) : handleSelect(e.target.value, "ASC") }}
					className={styles.filter_select}
					input={<OutlinedInput />}
					onOpen={() => setOpen((open) => !open)}
					onClose={() => { setOpen((open) => !open) }}
					open={open || isSorting}
					inputProps={{ 'aria-label': 'Without label' }}
					IconComponent={() => open ? <ExpandLessIcon style={stylebuttonIcon} /> : <ExpandMoreIcon style={stylebuttonIcon} />}
					sx={{ fontSize: "12px ", width: { xs: "140px", sm: "181px" } }}
				>
					{names ?
						names.options.map((name) =>
							<MenuItem key={name.value} value={(forSearch ? name.value + "--" + name.direction : name.value)} onClick={() => { setIsSorting(true) }}>
								<ListItemText primary={<Typography fontSize={14} sx={{ textOverflow: { xs: "ellipsis", md: "unset" }, overflow: { xs: "hidden", md: "visible" } }}>{name.label}</Typography>} />
							</MenuItem>
						)
						:
						null
					}
				</Select>
			</FormControl>
			{
				forSearch
					?
					null
					:
					<Button variant="outlined" className={styles.changeSortButton}
						onClick={(e) => {
							handleSelect(
								searchParams?.sort ?
									Object.keys(searchParams?.sort)[0]
									:
									forSearch ?
										"relevance"
										:
										names.default
								,
								document.querySelector('.sort_icon').getAttribute('sort')
							)
						}}
					>
						{
							variables?.sort && Object.values(Object.values(variables?.sort)).at(0) === "ASC"
								?
								<SortIcon style={{ transform: 'scaleY(-1)' }} className="sort_icon" sort="DESC" />
								:
								<SortIcon className="sort_icon" sort="ASC" />
						}
					</Button>
			}

		</Grid >
	);
}
export default DropDownSort;