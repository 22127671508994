import { useRouter } from "next/router"
import { cacheKey } from '.'
import parseSearchParams, { strEncode } from "../../helpers/SearchParams"
import { memo, useCallback, useEffect, useState } from "react"
import RenderData from "../product-list/renderData"

const Prev = ({ variables, renderConfigProps, prefetch = true, forSearch }) => {
    const router = useRouter()

    const getLink = useCallback((page) => {
        const searchParams = parseSearchParams(router)
        if (page >= 1) {
            searchParams.page = page
            if (router.asPath.includes('.html')) {
                return router.asPath.split('.html')[0] + ".html/" + strEncode(JSON.stringify(searchParams))
            } else if (router.asPath.includes('catalogsearch/result')) {
                return router.asPath.split('catalogsearch/result')[0] + "catalogsearch/result/" + strEncode(JSON.stringify(searchParams))
            }
        }
    }, [router])

    const cKey0 = "categoryCache"
    const cKey1 = cacheKey(variables.filters)
    const cKey2 = cacheKey(variables)

    const items1 = typeof window !== "undefined" ? window?.[cKey0]?.[cKey1]?.[cKey2] : null
    const [items, setItems] = useState(items1)

    useEffect(() => {
        if (typeof window !== "undefined" && !items) {
            window[cKey0] = (window[cKey0] || {})
            window[cKey0][cKey1] = (window[cKey0][cKey1] || {})
            window[cKey0][cKey1][cKey2] = []
            fetch(getLink(variables.currentPage))
                .then((response) => {
                    return response.text();
                })
                .then((htmlContent) => {
                    window[cKey0][cKey1][cKey2] = JSON.parse('{"items":' + htmlContent.split('{"data":{"products"')[1].split('"items":')[1].split(',"page_info"')[0] + "}")?.items;
                    setItems(JSON.parse('{"items":' + htmlContent.split('{"data":{"products"')[1].split('"items":')[1].split(',"page_info"')[0] + "}")?.items)
                })
                .catch((error) => {
                    console.error('Error fetching page content:', error);
                });
        }
    }, [cKey0, cKey1, cKey2, items])

    return (
        <>
            {variables.currentPage > 1
                ?
                <Prev variables={{ ...variables, currentPage: variables.currentPage - 1 }} renderConfigProps={renderConfigProps} prefetch={prefetch} forSearch={forSearch} />
                : null
            }
            {
                typeof window !== "undefined" && items && items?.length > 0
                    ?
                    <RenderData page={variables.currentPage} products={items} key={(cKey0 + cKey1 + cKey2)} {...renderConfigProps} forSearch={forSearch} />
                    :
                    null
            }
        </>
    )
}

export default memo(Prev);