import { Box, Divider, Grid, Typography } from "@mui/material";
import useCss from "../../hooks/useCss";
import { Link } from '../my-link/link';

const LeftSideCategorySelected = ({ styles, selectedCat, categorySelectedId }) => {

    const { c } = useCss()

    return (
        selectedCat?.children ?
            <Grid className={styles.cat_selected + " bg-light-gray"} sx={{ mt: 0, mb: 3 }}>
                <Typography className={styles.cat_title} textTransform="uppercase" fontWeight={600} >{selectedCat?.name}</Typography>
                <Divider style={{ borderBottomWidth: 2, background: c('black'), marginBottom: "30px" }} />
                <Box 
                    sx={{
                        maxHeight: "370px",
                        overflowY: "auto",
                        mx:"-12px",
                        px: "12px",
                        "&::-webkit-scrollbar": { width: 6 },
                        "&::-webkit-scrollbar-track": { background: '#F6F6F6' },
                        "&::-webkit-scrollbar-thumb": { background: '#ccc', borderRadius: 20 },
                    }}
                >
                    {selectedCat?.children?.map((item, index) =>
                        <Grid key={item.id}>
                            <Link className={styles.category_link} to={item?.url_path + ".html"}>
                                <Grid display={"flex"} justifyContent={"space-between"} alignItems="center" paddingY="8px" >
                                    <Typography className={styles.cat_selected_name} sx={{ color: item.id === categorySelectedId ? c('blue') : c('black') }} >{item?.name}</Typography>
                                    <Typography className={styles.cat_product_count} sx={{ color: item.id === categorySelectedId ? c('blue') : c('black') }}>{item?.product_count}</Typography>
                                </Grid>
                            </Link>
                            {index < selectedCat.children.length - 1 ? <Divider /> : null}
                        </Grid >
                    )}
                </Box>
            </Grid >
            :
            null
    )
}
export default LeftSideCategorySelected;