import { Grid, Typography } from '@mui/material'
import React from 'react'
import FrankenBreadCrumb from '../../components/franken-breadcrumb/frankenBreadCrumb'
import { CmsPageParser } from "../CmsParser"
import Image from 'next/image'

const ImageCategoryBaner = ({ imgSrc, data, styles }) => {


    return (
        <>
            {data.cms_block?.content
                ?
                <CmsPageParser>{data.cms_block?.content}</CmsPageParser>
                :
                imgSrc
                    ?
                    <Grid className={styles.category_baner}
                        sx={{
                            width: { xs: '88%', lg: "100%", md: "100%", sm: "93%" }, mb: 2, ml: { xs: 3, lg: 0, md: 0 },
                            mt: "2px", "& img": { width: "100%" }
                        }}
                    >
                        {
                            imgSrc
                                ?
                                <img src={imgSrc} alt={data?.name + "-image"} />
                                :
                                null
                        }
                        <Grid className={styles.category} sx={{ ml: { xl: '28px', xs: '0' }, textAlign: { xs: "center", lg: "left" }, width: { xs: '100%', xl: 'unset' }, pl: { xs: 2, sm: 3, lg: 1 } }}>
                            {data?.breadcrumbs ?
                                <Grid sx={{ justifyContent: { xl: 'unset', xs: 'left' }, display: 'flex', pl: "2px" }}>
                                    <FrankenBreadCrumb
                                        breadcrumbs={data.breadcrumbs}
                                        variant='plp'
                                    />
                                </Grid>
                                :
                                null
                            }
                            <Typography className={styles.title} component={'span'} sx={{ fontSize: { xl: '42px', xs: '28px', } }}>{data?.name}</Typography>
                        </Grid>
                    </Grid>
                    :
                    null
            }
        </>
    )
}

export default ImageCategoryBaner