import { useEffect, useState } from 'react';
import { Box, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, ListItemText, OutlinedInput, Select, Stack } from '@mui/material';
import styles from '../../styles/dropdownFilter.module.scss'
import useCss from '../../hooks/useCss';
import useTranslation from '../../hooks/useTranslation';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CloseIcon from '@mui/icons-material/Close';
import { useRouter } from 'next/router';
import parseSearchParams, { strEncode } from '../../helpers/SearchParams';

const DropDownFilter = ({ id, label = "", options, onReset }) => {
    const router = useRouter()
    const [searchParams1, setSearchParams1] = useState({})
    const singleSelect = id === "category_uid"
    const { t } = useTranslation()
    const { c } = useCss()
    const [open, setOpen] = useState(false)
    const [init, setInit] = useState(true)

    if (id === "category_uid") id = "category_id"
    useEffect(() => {
        setSearchParams1(parseSearchParams(router))
    }, [router])

    const getFilters = () => {
        if (searchParams1?.filters && searchParams1?.filters[id]) {
            return searchParams1?.filters[id]
        }
        else {
            return []
        }
    }



    const [filters, setFilters] = useState([])
    const [clearFilters, setClearFilters] = useState(false)

    const MenuProps = {
        PaperProps: {
            style: {
                /* maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP, */
                width: '10%',
                marginLeft: "0%",
            },
        },
    }

    useEffect(() => {
        setFilters(getFilters())
    }, [getFilters().length])

    useEffect(() => {
        if (clearFilters) {
            doFilters()
            setClearFilters(false)
        } else if (!init && singleSelect) {
            doFilters(filters)
        }
    }, [filters])

    const handleFilter = (e, value) => {
        const f = filters
        setInit(false)
        if (e.target.checked) {
            if (singleSelect)
                setFilters([value])
            else
                setFilters([...filters, value])
        }
        else {
            setFilters(f.filter(f => f !== value))
        }
    }

    const doFilters = (filters = []) => {
        let s = parseSearchParams(router)
        if (!s.filters) s.filters = {}
        s.filters[id] = filters

        if (s.filters[id].length === 0) {
            delete s.filters[id]
            if (Object.keys(s.filters).length === 0)
                delete s.filters
        }
        delete s.sort
        delete s.page
        var localParam = Object.keys(s).length > 0 ? "/" + strEncode(JSON.stringify(s)) : ""
        if (Object.keys(parseSearchParams(router)).length === 0)
            router.push(router.asPath + localParam)
        else
            router.push(router.asPath.split('/').slice(0, -1).join('/') + localParam)
    }

    const resetCurrentFilter = () => {
        setClearFilters(true)
        setFilters([])
    }

    return (
        <FormControl className={styles.filter_dropdown} sx={{ m: 1, mb: 2.8 }} >
            <Grid display={getFilters().length > 0 ? 'block' : 'none'} className={styles.reset_fliter}>
                <Button onClick={() => resetCurrentFilter()}>
                    <CloseIcon className='white' style={{ width: "1rem" }} />
                </Button>
            </Grid>
            <InputLabel variant="standard"
                sx={{
                    fontSize: "13.5px", overflow: "hidden", textOverflow: "ellipsis", color: c('black'),
                    fontWeight: "500", mr: "3px", maxWidth: "79%", top: "auto", left: "15px", bottom: "28px"
                }}
                id="multiple-checkbox-label">
                {label}
            </InputLabel>
            <Select
                className={filters.length > 0 ? "bg-light-black bg-hover-black f-white " + styles.filterSelect : styles.filterSelect}
                labelId="multiple-checkbox-label"
                id={"multiple-checkbox " + (label)}
                multiple
                value={filters}
                onOpen={() => setOpen((open) => !open)}
                onClose={() => { setOpen((open) => !open) }}
                open={open}
                input={<OutlinedInput label={label} />}
                renderValue={(selected) => selected.map(i => options.filter(x => x.value === i.toString())[0]?.label).join(', ')}
                MenuProps={MenuProps}
                IconComponent={() => open ? <ExpandLessIcon style={{ width: "1.4rem" }} /> : <ExpandMoreIcon style={{ width: "1.4rem" }} />}
                sx={{
                    '& .MuiInputBase-input.MuiOutlinedInput-input': { fontSize: '0.82rem' }, ':hover': {
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: c('dark-gray'),
                        }
                    }
                }}
            >
                <Grid className={styles.items} >
                    <Stack className={styles["item"]} maxHeight='200px' minHeight='20px' style={{ overflowY: "scroll" }} >
                        {options.sort((o1, o2) => o1.label?.toUpperCase() < o2.label?.toUpperCase() ? -1 : o1.label?.toUpperCase() > o2.label?.toUpperCase() ? 1 : 0).map((option) =>
                            (option.count > 0 && option.label !== "0" && option.label !== "0") ?
                                <MenuItem
                                    key={`${option.label}_${option.value}`} value={option.value}
                                    sx={{ width: "100%", '& span': { fontSize: "14px" }, '& svg': { fontSize: "1.3rem" }, px: 0.5, py: 0 }}
                                >
                                    <FormControlLabel
                                        sx={{
                                            width: "100%", mx: 0, ".MuiFormControlLabel-label": { whiteSpace: "break-spaces" },
                                            ".MuiFormControlLabel-label": { width: "100%" }
                                        }}
                                        control={<Checkbox
                                            checked={Array.isArray(filters) ? filters?.includes(option.value) : false}
                                            value={option.value}
                                            className="filterCheckbox"
                                            onChange={(e) => { handleFilter(e, option.value) }}
                                            sx={{ p: 1, my: 0.5 }}
                                        />}
                                        label={<Box display="flex" flex={1} justifyContent="space-between">
                                            <span>{option.label}</span>
                                            <span className="mx-1">({option.count})</span>
                                        </Box>}
                                    />

                                </MenuItem>
                                :
                                null
                        )}
                    </Stack>
                </Grid>
                {
                    !singleSelect
                        ?
                        <Grid marginY={2} paddingX={4}
                        >
                            <Button
                                sx={{
                                    boxShadow: 'unset', width: "100%", borderRadius: "5px", background: c('blue'), height: '33px',
                                    ':hover': { backgroundColor: c('light-black'), boxShadow: 'unset' }
                                }}
                                variant="contained"
                                onClick={() => {
                                    doFilters(filters);
                                }}>{t("Apply")}</Button>
                        </Grid>
                        :
                        null
                }
            </Select>
        </FormControl >
    );
}

export default DropDownFilter;