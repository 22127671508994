import { memo } from "react";
import LazyProducts from "../LazyProducts";
import ProductList from "../product-list/productList";
import PaginatedProductList from "../paginated-product-list";

const ProductCollection = ({ data, catData, storeConfig, forSearch, metaData, showLeftSide, variables, prefetch = true }) => {

    const isInfiniteScoll = true
    console.log(catData);

    const renderConfigProps = {
        metaData: metaData,
        storeData: storeConfig,
        category_path: (catData?.categories?.items?.at(0) && !catData?.categories?.items?.at(0)?.is_special) ? catData?.categories?.items?.at(0)?.url_path : null,
        showLeftSide: showLeftSide,
        variables: variables,
        sx: {},
    }


    return (
        isInfiniteScoll
            ?
            <LazyProducts prev={isInfiniteScoll} next={isInfiniteScoll} variables={variables} pagesCount={data.products.page_info?.total_pages} renderConfigProps={renderConfigProps} forSearch={forSearch}>
                <ProductList data={data} renderConfigProps={renderConfigProps} forSearch={forSearch} />
            </LazyProducts>
            :
            <PaginatedProductList data={data} renderConfigProps={renderConfigProps} forSearch={forSearch} />
    );
}

export default memo(ProductCollection);