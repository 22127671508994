import React, { useMemo, memo } from "react";
import { useRouter } from "next/router";
import Prev from "./prev-products";
import NextProducts from "./next-products";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Grid } from "@mui/material";


export const cacheKey = (variables) => {
    return JSON.stringify(variables)
}

const LazyProducts = ({ next = true, prev = true, renderConfigProps, variables, children, forSearch }) => {
    const router = useRouter()
    const pagesCount = useMemo(() => children.props?.data?.products?.page_info?.total_pages, [children])

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 450,
                smd: 525,
                md: 769,
                lg: 1024,
                xl: 1440,
                xxl: 1600
            }
        }
    });
    const columnsCount = renderConfigProps.showLeftSide
        ?
        { xs: 1, sm: 1, smd: 2, md: 2, lg: 2, xl: 4 }
        :
        { xs: 1, sm: 1, smd: 2, md: 2, lg: 3, xl: 4, xxl: 5 }

    return (
        <ThemeProvider theme={theme}>
            <Grid container columns={columnsCount} spacing={{ md: 4, xs: 3 }} sx={{ px: { lg: 'unset', xs: '0rem' }, mb: "32px" }}  >
                {prev && variables.currentPage > 1 && router.isReady
                    ?
                    <Prev variables={{ ...variables, currentPage: variables.currentPage - 1 }} renderConfigProps={renderConfigProps} forSearch={forSearch} />
                    :
                    null
                }
                {children}
                {
                    next && variables.currentPage < pagesCount && router.isReady
                        ?
                        <NextProducts pagesCount={pagesCount} variables={{ ...variables, currentPage: variables.currentPage + 1 }} renderConfigProps={renderConfigProps} forSearch={forSearch} />
                        :
                        null
                }
            </Grid>
        </ThemeProvider>
    );
};

export default memo(LazyProducts);
